<template>
  <div class="mainPage container">
    <div class="searchbox">
      <el-form ref="formList" :model="formList" label-width="80px" inline>
        <el-form-item label="商品名称">
          <el-input v-model="formList.goods_name"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formList.status" placeholder="请选择活动区域">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="未开始" :value="1"></el-option>
            <el-option label="进行中" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
            <el-option label="已禁用" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" size="small" @click="getList">查询</el-button>
      </el-form>
    </div>
    <div>
      <el-button type="primary" @click="toAdd">新增拼团</el-button>
    </div>
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <el-table-column
          v-for="item in tableHeaders"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :fixed="item.fixed"
        >
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1"
              type="text"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.status == 2 || scope.row.status == 4"
              type="text"
              @click="changeStatus(scope.row)"
              >{{
                scope.row.status == 2
                  ? "禁用"
                  : scope.row.status == 4
                  ? "开启"
                  : ""
              }}</el-button
            >
            <el-button type="text" @click="check(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-show="totalNum > 0"
      :total="totalNum"
      :page.sync="pagination.page"
      :limit.sync="pagination.pageNumber"
      @pagination="getList"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/components/Pagination/index";
export default {
  name: "teamworkManagementList",
  components: {
    Pagination,
  },
  data() {
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      formList: {},
      tableHeaders: [
        {
          label: "商品名称",
          prop: "goods_name",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "拼团类型",
          prop: "type",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "活动时间",
          prop: "actTime",
          width: "auto",
          align: "center",
          fixed: false,
        },

        {
          label: "拼团销量",
          prop: "real_sales",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "成团人数",
          prop: "user_num",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "参团新客数",
          prop: "new_user_num",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "状态",
          prop: "statusText",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: "auto",
          align: "center",
          fixed: false,
        },
      ],
      totalNum: 0,
      pagination: {
        page: 1,
        pageNumber: 10,
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      console.log(123);
      const data = {
        ...this.pagination,
        ...this.formList,
      };
      this.axios
        .post("/store/Shopactivity/getGroupActivityList", data)
        .then((res) => {
          this.tableData = res.data.list;
          this.tableData.map((el) => {
            el.actTime = `${this.dayjs(el.start_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            )}至${this.dayjs(el.end_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`;
            el.create_time = this.dayjs(el.create_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            el.statusText =
              el.status == 1
                ? "未开始"
                : el.status == 2
                ? "进行中"
                : el.status == 3
                ? "已结束"
                : el.status == 4
                ? "已禁用"
                : "";
            return el;
          });
          this.totalNum = res.data.total;
        });
    },
    toAdd() {
      this.$router.push({
        path: "/operation/teamworkManagement/addTeamworkManagement",
      });
    },
    check(val) {
      this.$router.push({
        path: "/operation/teamworkManagement/checkManagement",
        query: {
          group_id: val.group_id,
        },
      });
    },
    edit(val) {
      this.$router.push({
        path: "/operation/teamworkManagement/addTeamworkManagement",
        query: {
          isEdit: true,
          group_id: val.group_id,
        },
      });
    },
    changeStatus(val) {
      const data = {
        group_id: val.group_id,
        status: val.status == 2 ? "0" : val.status == 4 ? "1" : "",
      };
      this.axios
        .post("/store/Shopactivity/saveGroupActivityStatus", data)
        .then((res) => {
          this.getList();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.searchbox {
  .input-with-select {
    width: 240px;
    margin-left: 10px;
  }
}
</style>
